<template>
  <div id="app" style="padding:15px; overflow:auto; width:calc(100% - 30px)">
    <loading :loading="loading" text="提交中"></loading>
    <el-form ref="form" :model="branch"  label-width="100px">
      <el-form-item label="分校名称"  :required="true">
        <el-input type="text"  v-model="branch.name" placeholder="请输入"></el-input>
      </el-form-item>
      <el-form-item label="坐标-经度"  :required="true">
        <el-input type="number"  v-model="branch.lng" placeholder="请输入"></el-input>
      </el-form-item>
      <el-form-item label="坐标-纬度"  :required="true">
        <el-input type="number"  v-model="branch.lat" placeholder="请输入"></el-input>
      </el-form-item>
      <el-form-item label="CRM RID"  :required="true">
        <el-input type="text"  v-model="branch.crmRid" placeholder="请输入"></el-input>
      </el-form-item>
      <el-form-item label="CRM CID"  :required="true">
        <el-input type="text"  v-model="branch.crmCid" placeholder="请输入"></el-input>
      </el-form-item>
      <el-form-item label="状态"  :required="true">
        <el-radio v-model="branch.isDisabled" :label="false">启用</el-radio>
        <el-radio v-model="branch.isDisabled" :label="true">禁用</el-radio>
      </el-form-item>
      <el-form-item style="display: none">
        <el-button @click="submit()" id="layuiadmin-app-form-submit">提交</el-button>
      </el-form-item>
    </el-form>
  </div>
</template>
<script>
import {
  TipEnum,
  UrlEnum,
  request,
} from "../../../public/js/common-vue";
import Loading from "@/components/Loading";

export default {
  name: "BranchSave",
  data() {
    return {
      loading: false,
      id:0,
      branch:{
        name:'',
        lng:'',
        lat:'',
        crmRid:'',
        crmCid:''
      }
    }
  },
  components: {Loading},
  mounted() {
    if(request('id') != null && request('id') != ''){
      this.id = request('id');
      this.initData();
    }
  },
  methods:{
    submit:function (){
      if(this.branch.name == ''){
        this.showMsgError("请填写分校名称")
        return;
      }
      if(this.branch.lng == ''){
        this.showMsgError("请填写坐标-经度")
        return;
      }
      if(this.branch.lat == ''){
        this.showMsgError("请填写坐标-纬度")
        return;
      }
      if(this.branch.crmRid === ''){
        this.showMsgError("请填写CRM RID")
        return;
      }
      if(this.branch.crmCid === ''){
        this.showMsgError("请填写CRM CID")
        return;
      }
      this.loading = true;
      let url = UrlEnum.BRANCHES;
      let method = "post";
      if(this.id != 0){
        url += "/"+this.id;
        method = "put";
      }
      this.$http({
        method: method,
        url: url,
        data: this.branch,
      })
      .then((res) => {
        this.loading=false;
        this.showMsgSuccess(TipEnum.SUBMIT_SUCCESS);
        setTimeout(function () {
          window.parent.postMessage({
            msg: '',
            key: 'refresh'
          }, '*')
        }, 800);
      })
      .catch((res) => {
        this.loading=false;
        if(res.response != null){
          const data = res.response.data;
          this.showMsgError(data.message);
        }
      });
    },
    initData(){
      this.loading = true;
      this.$http({
        method: 'GET',
        url: UrlEnum.BRANCHES + "/" + request('id'),
        data: '',
      })
      .then((res) => {
        this.loading = false;
        this.branch = res.data;
      })
      .catch((res) => {
        this.loading=false;
        if(res.response != null){
          const data = res.response.data;
          this.showMsgError(data.message);
        }
      });
    },
  }
}
</script>

<style scoped>
</style>
